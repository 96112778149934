<template>
  <div class="mt-12 py-12 px-12 text-center" style="background-color: #656565">
    <div>
        <img
          style="
        position: absolute;
        right: -169px;
        width: 412px;
        opacity: 15%;"
          src="../../assets/SB icon.svg"
        />
      </div>
    <h1 class="white--text pb-6">{{$t('about.s4.GOALS')}}</h1>
    <p class="white--text pb-6 caption px-12">
     {{$t('about.s4.desc1')}}
    </p>
    <p class="white--text pb-2 caption px-12">
    {{$t('about.s4.desc2')}}
    </p>
    <p class="white--text pb-12 caption px-12">
      {{$t('about.s4.withappreciation')}}
    </p>

    <h4 class="white--text pb-6">
     {{$t('about.s4.Thecurrentcatalog')}}
    </h4>
    <v-btn outlined color="white">{{$t('about.s4.clickhere')}}</v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
};
</script>

<style lang="scss" scoped></style>
